import React from "react";
import PropTypes from "prop-types";
import {Helmet} from "react-helmet";
import {useLocation} from "@reach/router";
import {useStaticQuery, graphql} from "gatsby";

const query = graphql`
    query SEO {
        site {
            siteMetadata {
                defaultTitle: title
                titleTemplate
                defaultDescription: description
                siteUrl: url
                defaultImage: image
            }
        }
    }
`;

const Seo = ({seo, article}) => {
    const {pathname} = useLocation();
    const {site} = useStaticQuery(query);

    if (!seo) {
        return null;
    }

    const {defaultTitle, titleTemplate, defaultDescription, siteUrl, defaultImage} = site.siteMetadata;

    const data = {
        title: seo.title || defaultTitle,
        description: seo.description || defaultDescription,
        url: `${siteUrl}${pathname}`,
        ogTitle: seo.openGraph?.title || seo.title || defaultTitle,
        ogDescription: seo.openGraph?.description || seo.description || defaultDescription,
    };

    if (seo.openGraph?.image || defaultImage) {
        data.image = `${siteUrl}${seo.openGraph?.image?.[0]?.url || defaultImage}`;
    }

    return (
        <Helmet
            htmlAttributes={{
                lang: "ru",
            }}
            title={data.title}
            titleTemplate={titleTemplate}
        >
            <meta name="description" content={data.description} />
            <meta name="image" content={data.image} />
            <meta property="og:url" content={data.url} />
            <meta property="og:title" content={data.ogTitle} />
            <meta property="og:description" content={data.ogDescription} />
            {data.image && <meta property="og:image" content={data.image} />}{" "}
            {article && <meta property="og:type" content="article" />}{" "}
        </Helmet>
    );
};

export default Seo;

Seo.defaultProps = {
    article: false,
    seo: {
        title: "",
        description: "",
        openGraph: {
            title: "",
            description: "",
            image: [{url: ""}],
        },
    },
};

Seo.propTypes = {
    article: PropTypes.bool,
    seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
        openGraph: PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            image: PropTypes.arrayOf(PropTypes.shape({url: PropTypes.string})),
        }),
    }),
};
